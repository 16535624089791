import React, { FC } from 'react'
import classNamesBind from 'classnames/bind'
import css from './styles.module.scss'

interface OwnProps {
  actionText?: string
  center?: boolean
  centerContent?: boolean
  icon?: string
  noBorder?: boolean
  noBreaks?: boolean
  noMargin?: boolean
  noPadding?: boolean
  noTopBottomPadding?: boolean
  printTitle?: string
  noTitleMargin?: boolean
  smallMargin?: boolean
  marginTop?: boolean
  title?: string | null
  width?: number | string
  noPrint?: boolean
  action?: () => void
  centerTitle?: boolean
  smallTitle?: boolean
  marginTitle?: boolean
  plain?: boolean
  id?: string
  paddedTitle?: boolean
  flat?: boolean
  allowBreaks?: boolean
  children: React.ReactNode
}

const classNames = classNamesBind.bind(css)

const Card: FC<OwnProps> = ({
  center,
  noBreaks,
  children,
  noPadding,
  noTopBottomPadding,
  noBorder,
  noMargin,
  smallMargin,
  marginTop,
  centerContent,
  noPrint,
  plain,
  id,
  flat,
  width,
  allowBreaks,
  title,
  printTitle,
  centerTitle,
  smallTitle,
  paddedTitle,
  noTitleMargin,
  marginTitle,
  icon,
  action,
  actionText,
}: OwnProps) => {
  const divProps: Record<string, unknown> = {}
  if (id !== undefined && id !== null) divProps.id = id

  // Functions
  const containerStyle = () => {
    return width ? { maxWidth: `${width}px` } : {}
  }

  // Partials
  const renderTitle = () => {
    if (title) {
      return (
        <div
          className={classNames('title', {
            centerTitle,
            noTitleMargin,
            smallTitle,
            paddedTitle,
            marginTitle,
          })}
        >
          {renderIcon()}
          <div
            className={classNames('text')}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {renderAction()}
        </div>
      )
    }

    if (printTitle) {
      return (
        <div className={classNames('title', 'print-only')}>
          {renderIcon()}
          <div className={classNames('text')}>{printTitle}</div>
          {renderAction()}
        </div>
      )
    }

    return null
  }

  const renderIcon = () => {
    if (!icon) return null

    return <img className={classNames('icon')} src={icon} />
  }

  const renderAction = () => {
    if (!action || !actionText) return null

    return (
      <div className={classNames('action')} onClick={() => action()}>
        {actionText}
      </div>
    )
  }
  return (
    <div
      {...divProps}
      style={containerStyle()}
      className={`card-container ${classNames('container', {
        center,
        noPadding,
        noTopBottomPadding,
        noMargin,
        noBorder,
        smallMargin,
        noBreaks,
        noPrint,
        plain,
        flat,
        allowBreaks,
      })}`}
    >
      {renderTitle()}
      <div
        className={classNames('body', {
          marginTop,
          'center-content': centerContent,
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default Card
