import React, { FC } from 'react'
import classNameBind from 'classnames/bind'

import css from './styles.module.scss'
const classNames = classNameBind.bind(css)

interface OwnProps {
  short?: boolean
  children: React.ReactNode
}

const InputGroup: FC<OwnProps> = ({ short, children }: OwnProps) => {
  return <div className={classNames('container', { short })}>{children}</div>
}

export default InputGroup
