'use client'
import React, { ButtonHTMLAttributes, useEffect, useState } from 'react'
import Loader from '@crystal-eyes/components/elements/Loader/Loader'
import styles from './ActionButton.module.scss'
import Icon, { Icons } from '../Icon/Icon'

export type ButtonTheme =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'cancel-button'
  | 'destructive-button'
  | 'small'
  | 'only-text'

type ActionButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode | string
  className?: string
  active?: boolean
  loading?: boolean
  theme?: ButtonTheme
  successMessage?: string
  success?: boolean
}

export default function ActionButton({
  children,
  className,
  active,
  loading,
  theme,
  success,
  successMessage,
  ...props
}: ActionButtonProps) {
  const [successActive, setSuccessActive] = useState(false)

  useEffect(() => {
    if (success) {
      setSuccessActive(true)
      setTimeout(() => {
        setSuccessActive(false)
      }, 2000)
    }
  }, [success])

  return (
    <>
      {successActive && successMessage ? (
        <div className={styles.success}>
          <Icon icon={Icons.CheckmarkCircle} className={styles.successCheck} />
          <div className={styles.successMessage}>{successMessage}</div>
        </div>
      ) : (
        <button
          className={`CE action-button ${className ? className : ''} ${
            active ? 'active' : ''
          } ${theme ? theme : ''}`}
          {...props}
        >
          {loading ? <Loader size={16} /> : children}
        </button>
      )}
    </>
  )
}
