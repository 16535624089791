import React, { Fragment } from 'react'
import classNamesBind from 'classnames/bind'

import Button from '@dashboard/components/Button'
import Card from '@dashboard/components/Card'
import Modal from '@dashboard/components/Modal'

import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)

interface OwnProps {
  body: string
  buttonHref?: string
  buttonText?: string
  fixedHeight?: boolean
  link?: { text: string; href: string }
  hasRequestedDemo?: boolean
  image: string
  lighterTitle?: boolean
  noGap?: boolean
  noImageShadow?: boolean
  reverse?: boolean
  showDemo?: boolean
  smallMargin?: boolean
  subtitle?: string
  title: string
  tallImage?: boolean
  sameTab?: boolean
  showLine?: boolean
  bigImage?: boolean
  onClick?: () => void | null
  setDemo?: () => void
}

interface State {
  modalOpen: boolean
}

class MarketingSection extends React.Component<OwnProps, State> {
  constructor(props: OwnProps) {
    super(props)

    this.state = {
      modalOpen: false,
    }
  }

  // Render
  render() {
    const { smallMargin, noGap, bigImage, reverse } = this.props

    return (
      <div
        className={classNames('container', {
          smallMargin,
          noGap,
          bigImage,
          reverse,
        })}
      >
        {this.renderContent()}
        {this.renderModal()}
      </div>
    )
  }

  renderContent() {
    const { reverse } = this.props

    if (reverse) {
      return (
        <Fragment>
          {this.renderInfo()}
          {this.renderImage()}
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          {this.renderImage()}
          {this.renderInfo()}
        </Fragment>
      )
    }
  }

  renderInfo() {
    const {
      body,
      image,
      title,
      lighterTitle,
      noImageShadow,
      fixedHeight,
      reverse,
    } = this.props

    return (
      <div className={classNames({ reverse })}>
        {this.renderSubtitle()}
        <h1 className={classNames('title', { lighterTitle })}>{title}</h1>
        {this.renderLine()}
        <div className={classNames('hide-large', { fixedHeight })}>
          <img
            className={classNames('image', { noImageShadow }, { fixedHeight })}
            src={image}
          />
        </div>
        <p className={css.body} dangerouslySetInnerHTML={{ __html: body }} />
        {this.renderButtons()}
      </div>
    )
  }

  renderSubtitle() {
    const { subtitle } = this.props

    if (subtitle) return <h3 className={css.subtitle}>{subtitle}</h3>
  }

  renderLine() {
    const { showLine, reverse } = this.props

    if (!showLine) return null

    return <div className={classNames('line', { reverse })} />
  }

  renderImage() {
    const { image, noImageShadow, fixedHeight, tallImage } = this.props

    return (
      <div className={classNames('hide-small', { fixedHeight, tallImage })}>
        <img
          className={classNames('image', {
            noImageShadow,
            fixedHeight,
            tallImage,
          })}
          src={image}
        />
      </div>
    )
  }

  renderButtons() {
    const { buttonText, link, reverse } = this.props

    if (buttonText || link) {
      return (
        <div className={classNames('buttons', { right: reverse })}>
          {this.renderButton()}
          {this.renderLink()}
        </div>
      )
    }
  }

  renderButton() {
    const { buttonHref, buttonText, onClick, sameTab } = this.props

    if (buttonHref && buttonText) {
      return (
        <div className={classNames('button')}>
          <Button href={buttonHref} openNewTab={!sameTab}>
            {buttonText}
          </Button>
        </div>
      )
    } else if (onClick && buttonText) {
      return (
        <div className={classNames('button')}>
          <Button small onClick={onClick}>
            {buttonText}
          </Button>
        </div>
      )
    }
  }

  renderLink() {
    const { link } = this.props

    if (!link) return null

    return (
      <a
        className={css.link}
        href={link.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {link.text}
      </a>
    )
  }

  renderModal() {
    const { modalOpen } = this.state

    return (
      <Modal toggleModal={this._toggleModal} modalOpen={modalOpen}>
        <Card title="Thanks!" plain>
          <p className={css.body}>
            Our team will be in touch with you shortly.
          </p>
          <Button onClick={() => this._toggleModal()}>Close</Button>
        </Card>
      </Modal>
    )
  }

  // Functions
  _toggleModal = () => {
    const { modalOpen } = this.state

    this.setState({ modalOpen: !modalOpen })
  }
}

export default MarketingSection
