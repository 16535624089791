import React from 'react'
import classNamesBind from 'classnames/bind'

import ReactiveOverlay, {
  OverlayDirection,
} from '@dashboard/components/ReactiveOverlay'
import css from './styles.module.scss'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
const classNames = classNamesBind.bind(css)

interface OwnProps {
  text?: string
  icon?: string
  inline?: boolean
  light?: boolean
  smallMargin?: boolean
  smallText?: boolean
  blue?: boolean
  white?: boolean
  fixedPosition?: boolean
  leftTop?: boolean
  direction?: OverlayDirection
  children?: React.ReactNode
  noPadding?: boolean
  width?: string | number
}

export default class Tooltip extends React.Component<OwnProps> {
  render() {
    const { smallMargin, inline, blue } = this.props

    return (
      <div
        className={classNames('container', {
          smallMargin,
          inline,
          blue,
        })}
      >
        {this.renderContent()}
      </div>
    )
  }

  renderContent() {
    const { light, blue, fixedPosition, white } = this.props

    if (fixedPosition) return this.renderFixed()
    let color = ''
    if (white) color = 'white'
    if (blue) color = 'empty'

    return (
      <ReactiveOverlay
        withTransform
        overlayGap={12}
        preferredDirection={this.getPreferredPosition()}
        overlayContent={this.renderTip()}
      >
        <div className={classNames('icon', { light })} style={{ color: color }}>
          <Icon icon={Icons.Info} style={{ height: '16px', width: '16px' }} />
        </div>
      </ReactiveOverlay>
    )
  }

  renderFixed() {
    const { light, fixedPosition } = this.props

    return (
      <div className={classNames('icon', { light, fixedPosition })}>
        <Icon icon={Icons.Info} style={{ height: '16px', width: '16px' }} />
        {this.renderTip()}
      </div>
    )
  }

  renderTip() {
    const { text, smallText, children, fixedPosition, noPadding, width } =
      this.props

    const widthStyle = width === 'full' ? '100%' : width

    if (text) {
      return (
        <div
          className={classNames('tip', { smallText, fixedPosition, noPadding })}
          style={{ width: widthStyle }}
          dangerouslySetInnerHTML={{ __html: text || '' }}
        />
      )
    }

    return (
      <div className={classNames('tip', { smallText, fixedPosition })}>
        {children}
      </div>
    )
  }

  // Functions
  getPreferredPosition(): OverlayDirection {
    const { direction } = this.props

    return direction || 'bottomRight'
  }
}
