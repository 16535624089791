import React, { FC } from 'react'
import classNamesBind from 'classnames/bind'

interface OwnProps {
  noPadding?: boolean
  vertical?: boolean
  children: React.ReactNode
  className?: string
}

import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)

const Buttons: FC<OwnProps> = ({
  noPadding,
  vertical,
  children,
  className,
}: OwnProps) => {
  // Functions

  // Effects

  return (
    <div
      className={classNames('container', { noPadding, vertical }, className)}
    >
      {children}
    </div>
  )
}

export default Buttons
