const PRIVATE_DOMAINS = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com']
export const validateEmail = (email: string) => {
  const regex = /\S+@\S+\.\S+/
  return regex.test(String(email).toLowerCase())
}

export const extractDomainFromEmail = (email: string) => {
  if (!validateEmail(email)) return null
  const domain = email.split('@')[1]
  if (PRIVATE_DOMAINS.includes(domain)) return null
  return domain
}
