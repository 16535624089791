'use client'
import React, {
  FC,
  useState,
  ChangeEvent,
  CSSProperties,
  useRef,
  useEffect,
} from 'react'
import classNamesBind from 'classnames/bind'

import { DropdownOption } from '@dashboard/lib/types'

import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)

interface OwnProps {
  options: DropdownOption[]
  value: string | number | null
  height?: number
  placeholder?: string
  smallFont?: boolean
  maxHeight?: number
  maxWidth?: number
  minWidth?: number
  fontSize?: number
  color?: string
  growableOptions?: boolean
  searchable?: boolean
  flat?: boolean
  error?: string
  bolderLabel?: boolean
  labelPadding?: string
  inline?: boolean
  goUp?: boolean
  disabled?: boolean
  onChange: (option: DropdownOption) => void
}

const Dropdown: FC<OwnProps> = ({
  options,
  value,
  height,
  placeholder,
  smallFont,
  maxHeight,
  maxWidth,
  minWidth,
  fontSize,
  color,
  growableOptions,
  searchable,
  flat,
  error,
  bolderLabel,
  labelPadding,
  inline,
  goUp,
  disabled,
  onChange,
}: OwnProps) => {
  const SPACE = 32
  const UP = 38
  const DOWN = 40
  const ENTER = 13
  const TAB = 9
  const [active, setActive] = useState(false)
  const [hasFocus, setHasFocus] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const [query, setQuery] = useState('')

  const ref = useRef<HTMLInputElement>(null)

  // Effects
  useEffect(() => {
    document.addEventListener('click', escapeListener)

    return () => {
      document.removeEventListener('click', escapeListener)
    }
  }, [])

  // Functions
  const handleSelection = (option: DropdownOption) => {
    toggleActive()
    setQuery(option.label)
    onChange(option)
  }

  const getOptions = () => {
    const currentOption = getCurrentOption()

    if (!searchable || (currentOption && query == currentOption.label))
      return options

    return options.filter(option =>
      option.label.toLowerCase().startsWith(query.toLowerCase()),
    )
  }

  const getCurrentOption = () => {
    return options.find(option => option.value === value)
  }

  const toggleActive = () => {
    if (disabled) return

    setActive(!active)
  }

  const escapeListener = (e: MouseEvent) => {
    if (
      e.target instanceof Node &&
      ref.current &&
      !ref.current.contains(e.target)
    ) {
      setActive(false)
    }
  }

  const keyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    switch (e.keyCode) {
      case SPACE:
        toggleActive()
        e.preventDefault()
        break

      case UP:
        setSelectedIndex(
          ((selectedIndex ?? options.length) - 1 + options.length) %
            options.length,
        )
        setActive(true)
        e.preventDefault()
        break

      case DOWN:
        setSelectedIndex(((selectedIndex ?? -1) + 1) % options.length)
        setActive(true)
        e.preventDefault()
        break

      case ENTER:
        if (selectedIndex !== null) onChange(options[selectedIndex])
        toggleActive()
        e.preventDefault()
        break

      case TAB:
        if (selectedIndex !== null) onChange(options[selectedIndex])
        setActive(false)
        break
    }
  }

  const ensureActive = () => {
    setActive(true)
  }

  const handleFocus = () => {
    if (!hasFocus) {
      setHasFocus(true)
      setActive(true)
    }
  }

  const handleBlur = () => {
    const currentOption = getCurrentOption()
    const resetQuery = currentOption ? currentOption.label : ''
    setHasFocus(false)
    setQuery(resetQuery)
  }

  const handleQuery = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\s\s+/g, ' ')
    setQuery(value)
  }

  const getDisplayText = () => {
    const currentOption = options.find(option => option.value === value)
    const showPlaceholder = !currentOption || currentOption.value === null
    let currentLabel = currentOption && currentOption.label
    currentLabel = showPlaceholder ? placeholder || 'Select...' : currentLabel

    return currentLabel
  }

  // Partials
  const renderPlaceholder = () => {
    if (searchable) return renderSearchable()

    const text = getDisplayText()

    const heightString = height ? `${height}px` : 'inherit'
    const fontSizeString = fontSize ? `${fontSize}px` : 'inherit'
    const colorString = color ? color : 'inherit'
    const currentOption = options.find(option => option.value === value)
    const light = !currentOption

    return (
      <div
        className={classNames('placeholder', {
          flat,
          error,
          inline,
          disabled,
        })}
        style={{ height: heightString }}
        onClick={toggleActive}
        onKeyDown={keyDownHandler}
        tabIndex={0}
      >
        <div
          style={{
            fontSize: fontSizeString,
            color: colorString,
            padding: labelPadding ? labelPadding : undefined,
          }}
          className={classNames('label', { smallFont, light, bolderLabel })}
        >
          {text}
        </div>
        <div className={css.down}>
          <img src="/static/share/images/down.svg" />
        </div>
      </div>
    )
  }

  const renderSearchable = () => {
    const currentOption = getCurrentOption()
    const text = currentOption && !hasFocus ? currentOption.label : query
    const heightString = height ? `${height}px` : 'inherit'

    return (
      <div
        className={classNames('placeholder', { flat, error })}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={ensureActive}
        style={{ height: heightString }}
      >
        <form autoComplete="off">
          <input
            autoComplete="off"
            type={'search'}
            placeholder={placeholder ? placeholder : 'Select...'}
            onChange={handleQuery}
            className={classNames('label', { smallFont })}
            value={text}
          />
        </form>
        <div className={css.down}>
          <img src="/static/share/images/down.svg" />
        </div>
      </div>
    )
  }

  const renderOptions = () => {
    if (!active) return

    let styles: CSSProperties = {
      maxHeight: `${maxHeight || 300}px`,
      maxWidth: maxWidth ? `${maxWidth}px` : 'unset',
    }

    if (minWidth !== undefined) {
      styles.minWidth = `${minWidth}px`
    }

    if (height) styles = { ...styles, top: `${height + 5}px` }

    const options = getOptions()

    return (
      <div
        className={classNames('options', {
          growableOptions,
          flat,
          inline,
          goUp,
        })}
        style={styles}
      >
        {options.map(renderOption)}
      </div>
    )
  }

  const renderOption = (option: DropdownOption, index: number) => {
    const grey = option.grey
    const spacer = option.spacer
    const selected = option.value === value
    const hovered = index === selectedIndex

    return (
      <div
        className={classNames('option', {
          grey,
          spacer,
          smallFont,
          selected,
          hovered,
        })}
        onClick={() => handleSelection(option)}
        onMouseOver={() => setSelectedIndex(index)}
        key={option.value || String(index)}
      >
        {option.label}
      </div>
    )
  }

  return (
    <div
      className={classNames('container')}
      ref={ref}
      onMouseLeave={() => setSelectedIndex(null)}
    >
      {renderPlaceholder()}
      {renderOptions()}
    </div>
  )
}

export default Dropdown
